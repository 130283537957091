
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

$progress-height: 3px;

.stepper {
  width: 100%;
}
.stepper-head {
  border-radius: $base-border-radius;
  overflow: hidden;

  &__inner {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    box-sizing: border-box;
    counter-reset: stepper;
    background-color: $color-white;
    padding: 20px 16px;
    border-bottom: $progress-height solid $secondary-color;
  }

  &__item {
    flex-grow: 1;
    text-align: center;
    &::before {
      content: counter(stepper) '. ';
      counter-increment: stepper;
    }
  }

  &__progress {
    position: absolute;
    bottom: $progress-height * -1;
    left: 0;
    height: $progress-height;
    background: $color-primary-1-day;
    z-index: 3;
  }
}

.stepper-body__item {
  margin-top: 20px;
}
